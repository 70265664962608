export const SKILLS_LIST = [
    {
        name: "HTML5",
        image: "res/skillsLogos/html5.png",
        display: true,
    },
    {
        name: "CSS3",
        image: "res/skillsLogos/css3.png",
        display: true,
    },
    {
        name: "JavaScript",
        image: "res/skillsLogos/javascript.png",
        display: true,
    },
    {
        name: "React",
        image: "res/skillsLogos/react.png",
        display: true,
    },
    {
        name: "React Native",
        image: "res/skillsLogos/react-native.png",
        display: true,
    },
    {
        name: "Redux",
        image: "res/skillsLogos/redux.png",
        display: true,
    },
    {
        name: "Bootstrap",
        image: "res/skillsLogos/bootstrap.png",
        display: true,
    },
    {
        name: "Material UI",
        image: "res/skillsLogos/materialui.png",
        display: true,
    },
    {
        name: "NodeJS",
        image: "res/skillsLogos/nodejs.png",
        display: true,
    },
    {
        name: "MongoDB",
        image: "res/skillsLogos/mongodb.png",
        display: true,
    },
    {
        name: "C#",
        image: "res/skillsLogos/c-sharp.png",
        display: true,
    },
];