import React, { useState } from 'react';
import { makeStyles, Paper, Grow } from '@material-ui/core';
import VisibilitySensor from 'react-visibility-sensor';
import { SKILLS_LIST } from "../../shared/SkillsList";

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: "'Varela Round', sans-serif",
        color: "#fff",
        backgroundColor: "#000",
        [theme.breakpoints.down("sm")]: {
            padding: "50px 0",
        },
        [theme.breakpoints.up("md")]: {
            padding: "50px 10%",
        },
    },
    skillContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
    },
    skillSection: {
        backgroundColor: "#333",
        textAlign: "center",
        padding: "5px 0",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            width: "100px",
            margin: "2px",
        },
        [theme.breakpoints.up("md")]: {
            width: "150px",
            margin: "10px 25px",
        },
    },
    mainTitle: {
        textAlign: "center",
        fontSize: "4rem",
        marginBottom: "0",
    },
    image: {
        width: "90%",
        margin: "auto",
    },
    skillTitle: {
        margin: "0",
        alignSelf: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "1.5rem",
        },
    },
}));

export default function Skills() {
    const classes = useStyles();

    const skillsList = SKILLS_LIST.filter(skill => skill.display).map((skill, key) => {
        return (
            <Skill key={key} skill={skill} />
        );
    });

    return (
        <div className={classes.root} id="skills">
            <h2 className={classes.mainTitle}>Skills</h2>
            <div className={classes.skillContainer}>
                {skillsList}
            </div>
        </div>
    );
}

function Skill({skill}) {
    const classes = useStyles();

    const [isVisible, setVisible] = useState(false);

    function visibilityChange(newVisible) {
        setVisible(isVisible || newVisible);
    }

    return (
        <VisibilitySensor onChange={visibilityChange}>
            <Grow in={isVisible} timeout={750}>
                <Paper className={classes.skillSection}>
                    <img class={classes.image} src={skill.image} alt={`${skill.name} Logo`} />
                    <h3 className={classes.skillTitle}>{skill.name}</h3>
                </Paper>
            </Grow>
        </VisibilitySensor>
    );
}