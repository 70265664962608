import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, IconButton, Button } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { Link as Scroll } from 'react-scroll';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './socials.css';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: "'Varela Round', sans-serif",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        color: "#fff",
        position: "relative",
    },
    background: {
        position: "absolute",
        backgroundImage: `url(${process.env.PUBLIC_URL + '/res/earth.jpg'})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        height: "100vh",
        zIndex: "-2",
    },
    spacer: {
        height: "20vh",
        width: "100%",
        backgroundColor: "black",
        zIndex: "100",
    },
    container: {
        [theme.breakpoints.up("sm")]: {
            width: "60vw",
        }
    },
    title: {
        fontSize: "3rem",
    },
    connectionText: {
        textAlign: "right",
        margin: "5px",
        fontSize: "1.5rem",
    },
    socialLink: {
        textDecoration: "none",
        backgroundColor: "blue",
        textTransform: "none",
        color: "#fff",
        "&:hover": {
            backgroundColor: "darkblue",
        },
    },
    expandMoreContainer: {
        textAlign: "center",
    },
    expandMoreButton: {
        color: "#fff",
        fontSize: "4rem",
        zIndex: "1",
    },
    topFade: {
        position: "absolute",
        top: "0",
        backgroundImage: "linear-gradient(#000000FF, #00000000);",
        width: "100%",
        height: "250px",
        zIndex: "-1",
    },
}));

export default function Socials(props) {
    const classes = useStyles();
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(window.pageYOffset);

    const relativeY = offsetY - (window.innerHeight * props.order);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className={classes.root} id="socials">
            <div className={classes.topFade} />
            <div className={classes.background} style={{ backgroundPosition: `0px ${relativeY * 0.5}px` }} />
            <Grid container className={classes.container}>
                <Grid item xs={12}>
                    <h2 className={classes.title}>Connect with me</h2>
                </Grid>
                <Grid item xs={12}>
                    <p className={classes.connectionText}>Connect with me on <Button className={classes.socialLink} variant="contained" startIcon={<LinkedInIcon />} size="large" href="https://www.linkedin.com/in/mattnordstrand/" target="_blank" rel="noreferrer">LinkedIn</Button></p>
                </Grid>
                <Grid item xs={12}>
                    <p className={classes.connectionText}>Check out my <Button className={classes.socialLink} variant="contained" startIcon={<GitHubIcon />} size="large" href="https://github.com/MatthewNordstrand" target="_blank" rel="noreferrer">GitHub</Button></p>
                </Grid>
                <Grid item xs={12}>
                    <p className={classes.connectionText}>Send me an <Button className={classes.socialLink} variant="contained" startIcon={<MailOutlineIcon />} size="large" href="mailto:contact@mattnordstrand.com">Email</Button></p>
                </Grid>
                <Grid item xs={12} className={classes.expandMoreContainer}>
                    <Scroll to="skills" smooth={true}>
                        <IconButton>
                            <ExpandMoreIcon className={classes.expandMoreButton} />
                        </IconButton>
                    </Scroll>
                </Grid>
            </Grid>
            <div class="bottom-divider">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
                    <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
                    <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
                </svg>
            </div>
        </div>
    );
}
