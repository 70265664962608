export const PROJECTS_LIST = [
    {
        name: "Tech R Us",
        github: "",
        deployed: "",
        desc: "This project was made as a tech demo during potential employment. It's all frontend and containes a cart, a search feature using Fuse.js and simulated purchase processing. I do plan on actually continuing on this project as a future storefront software. Source code will be available at some point in the future.",
        tech: [
            "Javascript",
            "React",
            "Redux",
            "Fuse.js",
        ],
        images: [
            "res/projects/techrus/1.png",
            "res/projects/techrus/2.png",
            "res/projects/techrus/3.png",
        ],
        links: []
    },
    {
        name: "Faux Stocks",
        github: "https://github.com/MatthewNordstrand/Faux-Stocks",
        deployed: "",
        desc: "Fictional stock trading game that functions based on real stock data from a stock market API. This app works as a native app on Android and ios as well as on the web.",
        tech: [
            "Javascript",
            "React",
            "Redux",
            "Financial Modeling Prep API",
        ],
        images: [
            "res/projects/fauxstocks/1.png",
            "res/projects/fauxstocks/2.png",
            "res/projects/fauxstocks/3.png",
        ],
        links: [
            {
                text: "View Source",
                url: "https://github.com/MatthewNordstrand/Faux-Stocks"
            }
        ]
    },
    {
        name: "Epsilon Bug Tracker",
        github: "https://github.com/MatthewNordstrand/epsilon-bug-tracker",
        deployed: "",
        desc: "This software provides a service for tracking bugs. It is currently still under construction, so there is no deployed preview, but the source code is available to view right now. It uses Redux for state management, and the Auth0 API for user authentication.",
        tech: [
            "JavaScript",
            "React",
            "Redux",
            "Material UI",
            "Auth0",
        ],
        images: [
            "res/projects/epsilonbugtracker/1.png",
            "res/projects/epsilonbugtracker/2.png",
            "res/projects/epsilonbugtracker/3.png",
        ],
        links: [
            {
                text: "View Source",
                url: "https://github.com/MatthewNordstrand/epsilon-bug-tracker"
            }
        ]
    },
    {
        name: "Purfect Pals",
        github: "https://github.com/MatthewNordstrand/purfectpals",
        deployed: "https://www.mattnordstrand.com/purfectpals/",
        desc: "Fully responsive website made with Bootstrap. This website is designed for an animal adoption service. It contains good call to actions, many interactive elements like on the FAQ page, a few different forms, and a subscription levels page that you can see on many modern subscription services today.",
        tech: [
            "HTML5",
            "CSS3",
            "Bootstrap",
            "SASS",
        ],
        images: [
            "res/projects/purfectpals/1.png",
            "res/projects/purfectpals/2.png",
            "res/projects/purfectpals/3.png",
            "res/projects/purfectpals/4.png",
            "res/projects/purfectpals/5.png",
            "res/projects/purfectpals/6.png",
        ],
        links: [
            {
                text: "View Deployment",
                url: "https://www.mattnordstrand.com/purfectpals/"
            },
            {
                text: "View Source",
                url: "https://github.com/MatthewNordstrand/purfectpals"
            }
        ]
    },
];