import "./ProjButton.css";

import React from 'react';
import { Button } from '@material-ui/core';

export default function ProjButton({link}) {
    return (
        <Button
            className="button"
            variant="contained"
            color="primary"
            href={link.url}
            target="_blank"
        >
            {link.text}
        </Button>
    );
}