import React from 'react';
import { makeStyles, CssBaseline } from '@material-ui/core';
import Header from './sections/Header/Header';
import Skills from './sections/Skills/Skills';
import Projects from './sections/Projects/Projects';
import Socials from './sections/Socials/Socials';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
    }
}));

export default function App() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Header />
            <Socials order={1} />
            <Skills />
            <Projects />
        </div>
    );
}