import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { PROJECTS_LIST } from "../../shared/projectsList";
import ProjButton  from './ProjButton/ProjButton';
import './projects.css';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: "'Varela Round', sans-serif",
        minHeight: "100vh",
        color: "#fff",
        padding: "50px 10%",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
            padding: "50px 0",
        },
    },
    projectsContainer: {
        position: "relative",
    },
    background: {
        position: "absolute",
        backgroundImage: `url(${process.env.PUBLIC_URL + '/res/universe.jpg'})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        height: "100%",
        zIndex: "-1",
    },
    mainTitle: {
        textAlign: "center",
        fontSize: "4rem",
        marginBottom: "0",
    },
    containerPaper: {
        fontSize: "1.5rem",
        color: "#fff",
        backgroundColor: "#000000aa",
        padding: "0 16px 16px 16px",
        margin: "16px 0 32px 0",
        border: "2px solid black",
        [theme.breakpoints.down("xs")]: {
            padding: "0",
        },
    },
    internalPaper: {
        backgroundColor: "#000000ff",
        color: "#fff",
        margin: "0 16px",
        [theme.breakpoints.down("xs")]: {
            margin: "0",
        },
    },
    projectHeading: {
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            margin: "0",
        },
    },
    description: {
        padding: "16px",
        [theme.breakpoints.down("xs")]: {
            padding: "5px",
        },
    },
    viewButtonContainer: {
        padding: "16px",
        justifyContent: "space-around",
        [theme.breakpoints.down("xs")]: {
            padding: "0",
        },
    },
    galleryArea: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        padding: "16px",
    },
    galleryImage: {
        width: "300px",
        margin: "4px",
        border: "2px solid black",
        transition: "transform .2s",
        "&:hover": {
            transform: "scale(1.5)"
        }
    },
    linkTitle: {
        textAlign: "center",
    }
}));

export default function Projects() {
    const classes = useStyles();
    const [offsetY, setOffsetY] = useState(0);
    const handleScroll = () => setOffsetY(-document.getElementById("projects").getBoundingClientRect().top);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const projects = PROJECTS_LIST.map(project => {
        return (
            <Project project={project} />
        );
    });

    return (
        <div className={classes.projectsContainer}>
            <div className={classes.background} style={{ backgroundPosition: `0px ${offsetY * 0.5}px` }} />
            <div className={classes.root} id="projects">
                <div class="top-divider">
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                        <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                    </svg>
                </div>
                <h2 className={classes.mainTitle}>Projects</h2>
                {projects}
            </div>
        </div>
        
    )
}

function Project({project}) {
    const classes = useStyles();

    const tech = project.tech.map(tech => {
        return (
            <li>{tech}</li>
        );
    });

    const links = project.links.map(link => {
        return (
            <ProjButton link={link} />
        );
    });

    const images = project.images.map(image => {
        return (
            <GalleryImage imageUrl={image} />
        );
    });

    return(
        <Paper className={classes.containerPaper}>
            <Grid container>
                <Grid item xs={12}>
                    <Paper className={classes.internalPaper}>
                    <h2 className={classes.projectHeading}>{project.name}</h2>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Paper className={classes.internalPaper}>
                        <ul>
                            {tech}
                        </ul>
                    </Paper>
                    {project.links.length >= 1 &&
                        <Paper className={classes.internalPaper}>
                            <Typography variant="h5" className={classes.linkTitle}>Links</Typography>
                            <div className={classes.viewButtonContainer}>
                                {links}
                            </div>
                        </Paper>
                    }
                </Grid>
                <Grid container xs={12} md={8}>
                    <Grid item xs={12}>
                        <Paper className={classes.internalPaper}>
                            <p className={classes.description}>
                                {project.desc}
                            </p>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.galleryArea}>
                            {images}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

function GalleryImage({imageUrl}) {
    const classes = useStyles();

    return (
        <a href={imageUrl} target="_blank" rel="noreferrer">
            <img src={imageUrl} className={classes.galleryImage} alt="" />
        </a>
    );
}