import React, { useState, useEffect } from 'react';
import { AppBar, Drawer, Fade, IconButton, List, ListItem, makeStyles, Toolbar, Zoom, Typography, Hidden } from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link as Scroll } from 'react-scroll';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: "'Varela Round', sans-serif",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        color: "#fff",
        position: "relative",
    },
    background: {
        position: "absolute",
        backgroundImage: `url(${process.env.PUBLIC_URL + '/res/bg.jpg'})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100%",
        height: "100vh",
        zIndex: "-1",
    },
    drawer: {
        width: 300,
        height: "100%",
        backgroundColor: "#303030",
    },
    drawerText: {
        fontFamily: "'Baloo Chettan 2', calibri, arial",
        fontSize: "2rem",
        color: "#fff",
    },
    appbar: {
        backgroundColor: "#000000aa",
    },
    appbarWrapper: {
        width: "80%",
        margin: "0 auto",
    },
    appbarTitleContainer: {
        flexGrow: "1",
    },
    appbarName: {
        margin: "0",
    },
    appbarSubtitle: {
        margin: "0",
    },
    appbarEmail: {
        color: "#fff",
        margin: "0 0 5px 0",
    },
    icon: {
        color: '#fff',
        fontSize: "2rem",
    },
    colorText: {
        color: "#FF5A3D"
    },
    headerCenter: {
        textAlign: "center",
    },
    callToAction: {
        fontSize: "3rem",
        backgroundColor: "#000000aa",
        padding: "0 8px",
    },
    expandMoreButton: {
        color: "#fff",
        fontSize: "4rem",
        zIndex: "1",
    },
    bottomFade: {
        position: "absolute",
        bottom: "0",
        backgroundImage: "linear-gradient(#00000000, #000000FF);",
        width: "100%",
        height: "250px",
        zIndex: "-1",
    },
    DownloadResumeButton: {
        zIndex: "1",
    },
}));

export default function Header() {
    const classes = useStyles();
    const [offsetY, setOffsetY] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleScroll = () => setOffsetY(window.pageYOffset);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    function toggleDrawer() {
        setDrawerOpen(!drawerOpen);
    }

    return (
        <div className={classes.root} id="home">
            <div className={classes.background} style={{ backgroundPosition: `0px ${offsetY * 0.5}px` }} />
            <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
                <List className={classes.drawer}>
                    <Scroll to="home" smooth={true}>
                        <ListItem button onClick={toggleDrawer}>
                            <span className={classes.drawerText}>Home</span>
                        </ListItem>
                    </Scroll>
                    <Scroll to="socials" smooth={true}>
                        <ListItem button onClick={toggleDrawer}>
                            <span className={classes.drawerText}>Social Media</span>
                        </ListItem>
                    </Scroll>
                    <Scroll to="skills" smooth={true}>
                        <ListItem button onClick={toggleDrawer}>
                            <span className={classes.drawerText}>Skills</span>
                        </ListItem>
                    </Scroll>
                    <Scroll to="projects" smooth={true}>
                        <ListItem button onClick={toggleDrawer}>
                            <span className={classes.drawerText}>Projects</span>
                        </ListItem>
                    </Scroll>
                </List>
            </Drawer>
            <Hidden xsDown>
                <AppBar className={classes.appbar} elevation={0}>
                    <Toolbar className={classes.appbarWrapper}>
                        <div className={classes.appbarTitleContainer}>
                            <Hidden smDown>
                                <h1 className={classes.appbarName}>Matt Nordstrand</h1>
                            </Hidden>
                            <h4 className={classes.appbarSubtitle}>Full Stack Web Developer & AWS and Terraform Certified Cloud Engineer</h4>
                            <a  className={classes.appbarEmail} href="mailto:matt_nordstrand@live.com">
                                <Typography variant="caption">matt_nordstrand@live.com</Typography>
                            </a>
                        </div>
                        
                        {/* <Hidden smDown>
                            <DownloadResumeButton />
                        </Hidden> */}

                        <IconButton onClick={toggleDrawer}><SortIcon className={classes.icon} /></IconButton>
                    </Toolbar>
                </AppBar>
            </Hidden>
            <div className={classes.headerCenter}>
                <Zoom in={true} timeout={500} style={{transitionDelay: "500ms"}}>
                    <h2 className={classes.callToAction}>Hi. I am <span className={classes.colorText}>Matt Nordstrand</span><br/>and I make web and<br />mobile apps work.</h2>
                </Zoom>
                    
                {/* <Hidden mdUp>
                    <DownloadResumeButton />
                </Hidden> */}

                <div>
                    <Fade in={true} timeout={500} style={{transitionDelay: "1000ms"}}>
                        <Scroll to="socials" smooth={true}>
                            <IconButton>
                                <ExpandMoreIcon className={classes.expandMoreButton} />
                            </IconButton>
                        </Scroll>
                    </Fade>
                </div>
            </div>
            <div className={classes.bottomFade} />
        </div>
    );
}

// function DownloadResumeButton() {
//     const classes = useStyles();
    
//     return (
//         <Button className={classes.DownloadResumeButton} variant="contained" href="res/Matt-Nordstrand-Resume.pdf" target="_blank" rel="noreferrer">Download Resume</Button>
//     );
// }